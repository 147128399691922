*,
::before,
::after {
  box-sizing: border-box;
}

:root {
  --color-bg: #f5f5f5;
  --color-fg: #263238;

  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-bg: #263238;
    --color-fg: #f5f5f5;
  }
}

html {
  background-color: var(--color-bg);
  color: var(--color-fg);
}

.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  padding-bottom: 2rem;
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

h1 {
  font-size: 2rem;
}

h2 {
  margin: 2rem 0 1rem;
  font-weight: normal;
  font-size: 1.6rem;
}

h3 {
  font-weight: normal;
  font-size: 1.2rem;
}

h4, h5, h6 {
  font-size: 1rem;
}

svg {
  background: rgba(255, 255, 255, .025);
  border-radius: .5rem;
  box-shadow: 0 .5rem 1rem -.25rem rgba(0, 0, 0, .5);
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

g {
  overflow: visible;
}

rect:not([fill]) {
  fill: #546e7a;
}

text {
  font-size: .75rem;
  font-weight: bold;
  fill: var(--color-fg);
  text-shadow:
    0 1px 1px var(--color-bg),
    1px 0px 1px var(--color-bg),
    0 -1px 1px var(--color-bg),
    -1px 0 1px var(--color-bg);
}

.tick text {
  font-size: .6rem;
}

.pie-chart__slice {
  position: relative;
  z-index: 1;
}

.pie-chart__label {
  position: relative;
  z-index: 2;
}

.pie-chart__title {
  font-size: 1.2rem;
}

.node {
  opacity: 0.75;
}

.link {
  opacity: 0.35;
}

.link-description {
  opacity: 0;
  transition: opacity .2s ease-out;
}

.link:hover + .link-description {
  opacity: 1;
}

fieldset {
  margin-bottom: 1rem;
  font-size: .8rem;
  border: 0;
}

.filters {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.filters__field {
  display: flex;
  justify-content: stretch;
  align-items: center;
}
